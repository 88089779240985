body {
  background-color: #2b2b2b;
  color: #e0e0e0;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

header, footer {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: linear-gradient(90deg, #1c1c1c, #333);
  box-sizing: border-box;
}

header {
  padding: 20px 0;
  font-size: 2.5em;
  color: #ffffff;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

footer {
  padding: 0 0 10px 0;
  font-size: 1em;
  color: #ffffff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  position: relative;
}

footer a {
  color: #aaaaaa;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 4px;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
}

footer a:hover {
  color: #ffffff;
  background-color: #444;
  border: 1px solid #555;
}

footer .left {
  padding-left: 10px;
}

footer .middle {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

footer .middle a:hover {
  text-decoration: underline;
}

.App {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  box-sizing: border-box;
}

.articles-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(385px, 1fr));
  gap: 20px;
  padding: 20px 0;
  box-sizing: border-box;
}

.article-box {
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}

.article-box h3 {
  color: #cccccc;
  margin-bottom: 10px;
}

.article-box p {
  color: #c2c2c2;
  margin-bottom: 10px;
}

.article-box p.author {
  font-weight: bold;
  color: #ffffff;
}

.article-box p.domain {
  font-weight: bold;
  color: #ffffff;
}

.article-box .date {
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 0.9em;
  color: #aaaaaa;
}

.article-box a {
  margin-top: auto;
  color: #66b2ff;
  text-decoration: none;
}

.article-box a:hover {
  text-decoration: underline;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  header {
    font-size: 1.8em;
  }

  .filter-container {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    position: relative;
    z-index: 1000; 
  }

  .filter-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #444;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 1em;
    border-radius: 4px;
    transition: background-color 0.3s;
    z-index: 1000;
    margin: 0 auto;
    position: relative; 
  }

  .filter-button:hover {
    background-color: #555;
  }

  .articles-container {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  footer {
    flex-direction: column;
    height: auto;
    padding: 10px 0;
  }

  footer .middle {
    position: static;
    transform: none;
    margin-top: 10px;
  }
}

.filter-container {
  text-align: left;
  margin: 20px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.filter-button {
  background-color: #444;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.filter-button:hover {
  background-color: #555;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .filter-container {
    position: relative; 
    top: auto; 
    left: 75%; 
    transform: translateX(-50%); 
    margin-top: 20px; 
    margin-bottom: 20px; 
    z-index: 1000; 
  }

  .filter-button {
    display: inline-block;
    margin: 0 auto;

  }
}

.dropdown-menu {
  display: block;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 8px;
  padding: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  min-width: 250px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.dropdown-menu.show {
  visibility: visible;
  opacity: 1;
}

.dropdown-menu label {
  display: block;
  color: #e0e0e0;
  margin: 5px 0;
}

.dropdown-menu input {
  margin-right: 10px;
}

.no-articles {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e0e0e0;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 300px;
  grid-column: span 3;
  text-align: center;
  box-sizing: border-box;
}

.category-group {
  margin-bottom: 10px; 
}

.category-group > label {
  font-weight: bold; 
  margin-left: 0; 
}

.source-list {
  margin-left: 20px; 
}

.source-item {
  display: block; 
  margin-left: 0; 
}
